<template>
  <div id="page">
    <section class="product-list-heading box-my-account mb-0">
      <div class="container-fluid">
        <div class="row">
          <ProfileNavbar :parentData="userData.full_name"></ProfileNavbar>
          <div class="col-md-9 py-5 px-lg-5" v-show="!manageAddress">
            <h2>My Address</h2>
            <div class="mt-4">
              <ul class="row my-address list-unstyled">
                <li
                  class="col-md-6"
                  v-for="(addressData, index) in addressListData"
                  :key="index"
                >
                  <div class="bg-grey p-4">
                    <div
                      class="d-flex justify-content-between align-items-start"
                    >
                      <h3>{{ addressData.name }}</h3>
                      <div
                        v-if="addressData.is_primary"
                        class="
                          bg-instock
                          rounded
                          d-inline-block
                          px-2
                          py-1
                          instock
                          text-white
                          main-address-status
                        "
                      >
                        Main Address
                      </div>
                    </div>
                    <p>
                      {{ addressData.address }}
                    </p>
                    <ul
                      class="
                        list-unstyled
                        mt-auto
                        d-flex
                        align-items-center
                        justify-content-end
                      "
                    >
                      <li v-if="!addressData.is_primary">
                        <a
                          style="cursor: pointer"
                          @click="setMainAddress(addressData)"
                          class="edit"
                          ><img
                            src="@/assets/img/set-main-address.svg"
                            alt=""
                            width="24"
                            height="24"
                          />
                          Set As Main</a
                        >
                      </li>
                      <li>
                        <a
                          style="cursor: pointer"
                          @click="editAddress(addressData)"
                          class="edit"
                          ><img
                            src="@/assets/img/edit.png"
                            alt=""
                            width="24"
                            height="24"
                          />
                          Edit</a
                        >
                      </li>
                      <li v-if="!addressData.is_primary">
                        <a
                          style="cursor: pointer"
                          @click="selectedAddress = addressData"
                          v-b-modal="'modal-delete-address'"
                          class="edit"
                          ><img
                            src="@/assets/img/delete-icon.svg"
                            alt=""
                            width="20"
                            height="20"
                          />
                          Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-6">
                  <div class="bg-grey justify-content-center">
                    <a
                      @click="addAddressMenuActive()"
                      href="#"
                      class="add-address"
                    >
                      <img src="@/assets/img/ic-plus.svg" alt="" />
                      <span>Add Address</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-9 py-5 px-lg-5" v-if="manageAddress && editAddressId">
            <SaveAddress
              @saveAddressResponse="refreshData"
              :parentData="editAddressId"
            />
          </div>
          <div
            class="col-md-9 py-5 px-lg-5"
            v-if="manageAddress && !editAddressId"
          >
            <SaveAddress
              :hasMainAddress="hasMainAddress"
              @saveAddressResponse="refreshData"
            />
          </div>
        </div>
      </div>
    </section>
    <b-modal
      id="modal-delete-address"
      modal-class="p-0"
      centered
      hide-header
      @ok="deleteAddress(selectedAddress)"
    >
      <div class="modal-dialog" role="document" style="display: contents">
        <div class="modal-content" style="display: contents">
          <div class="modal-body p-0 pt-3">
            Are you sure you want to delete this address?
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { addressService } from "@/_services";
import { toaster } from "@/_helpers";
import ProfileNavbar from "@/components/mains/ProfileNavbar.vue";

import SaveAddress from "@/components/addresses/SaveAddress";
// import EditAddress from "@/components/addresses/EditAddress.vue";
// import AddAddress from "@/components/addresses/AddAddress.vue";

export default {
  components: {
    ProfileNavbar,
    SaveAddress,
    // EditAddress,
    // AddAddress,
  },
  data() {
    return {
      addressListData: [],
      editAddressId: null,
      manageAddress: false,
      selectedAddress: null,
      hasMainAddress: false,
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.getAddress();
  },
  methods: {
    setProductImgAltText(product) {
      return `${product.brand_name} ${product.name}${product.kadar > 0 ? ` with purity of ${product.kadar}` : ''}`
    },
    refreshData() {
      this.getAddress();
      this.manageAddress = false;
      this.editAddressId = null;
      this.scrollToTop();
    },
    addAddressMenuActive() {
      this.manageAddress = true;
    },
    editAddress(address) {
      this.editAddressId = address;
      this.manageAddress = true;
    },
    async deleteAddress(address) {
      try {
        const res = await addressService.deleteAddress(address.id);
        if (res.status === 200) {
          this.refreshData();
          toaster.make(res.data.message, "success");
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getAddress() {
      try {
        const res = await addressService.getAvailableAddress();
        if (res.status === 200) {
          this.addressListData = res.data.data;
          this.hasMainAddress = this.addressListData.length > 0 ? true : false;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async setMainAddress(address) {
      try {
        const res = await addressService.setPrimaryAddress(address.id);
        if (res.status === 200) {
          this.refreshData();
          toaster.make(res.data.message, "success");
        }
      } catch (e) {
        console.error(e);
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>

</style>

